import React from 'react'
import Layout from '../../components/layout'
import EllisHelmet from '../../components/EllisHelmet'
import LocationMenu from '../../components/location-specific/LocationMenu'
import ArtistsPage from '../../components/location-specific/ArtistsPage'

export default function ArtistsBryant() {
	const location = "bryant";
	const currentPage = "artists";
	const title = "Artists";

	return (
		<Layout className="menuPage" location={location} currentPage={currentPage}>
			<EllisHelmet page={currentPage} title={title} location={location} />
			<LocationMenu location={location} currentPage={currentPage} />
			<ArtistsPage location={location} />
		</Layout>
	)
}
