import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Menu from '../Menu'
import { formatDatoCMSData } from '../../services/datoCMS.service'

export default function ArtistsPage(props) {
  const query = useStaticQuery(graphql`
  query artistQuery {
    datoCmsArtist {
      artist
      email
    }
  }`);

  const artistsData = formatDatoCMSData(query);
  const artist = artistsData.artist;
  const email = artistsData.email;

  return (
    <div id="artists" className="wrapper style1">
      <div className="container">
        <Menu>
          <header className="major">
            <h2>Artists</h2>
          </header>
          <div className="box alt">
            <div className="row">
              <div className="col-8 col-12-xsmall">
                <p>Each month, our store seeks to showcase local artists' work throughout our shop. This month we are currently hosting the work of: <span style={{fontWeight: 'bold'}}>{artist}</span></p>
                <p>Applicants interested in showcasing their work are asked to please send a variety of images from your portfolio to <a href={"mailto:" + email}>{email}</a> of which they will be reviewed.</p>
              </div>
            </div>
          </div>
        </Menu>
      </div>
    </div>)
}
